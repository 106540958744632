import React, { FC, useEffect, useState } from "react"
import Slider from "react-slick"
import { getSiteReviews, SiteReview } from "@lib/data/_types"

type ReviewsProps = {
    reviews: any[]
    swiperElRef: any
}

const Reviews: FC<ReviewsProps> = ({ reviews, swiperElRef }) => {
    if (swiperElRef === null) return null
    const [siteReviews, setSiteReviews] = useState<Array<SiteReview>>([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getSiteReviews().then((res) => {
            setSiteReviews(res)
            setLoading(false)
        })
    }, [])
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    }
    if (loading) return null
    return (
        <Slider ref={swiperElRef} {...settings}>
            {siteReviews.map((review, index) => (
                <div key={`swipe-${index}`}>
                    <blockquote
                        key={`review-${index}`}
                        className="flex h-full flex-col justify-between bg-white p-12"
                    >
                        <div>
                            <div className="flex gap-0.5 text-highlight">
                                {Array.from({ length: review.stars }).map(
                                    (_, index) => (
                                        <svg
                                            className="h-5 w-5"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                        </svg>
                                    )
                                )}
                            </div>

                            <div className="mt-4">
                                <p
                                    className="mt-4 leading-relaxed text-gray-500"
                                    dangerouslySetInnerHTML={{
                                        __html: review?.review
                                    }}
                                ></p>
                            </div>
                        </div>

                        <footer className="mt-8 text-sm text-gray-500">
                            &mdash; {review?.fullName}
                        </footer>
                    </blockquote>
                </div>
            ))}
        </Slider>
    )
}

export default Reviews
